<template>
  <div class="shopping">
    <div class="shopping_content">
      <div class="header2" >
        <div class="nav flex">
        当前位置：
          <el-breadcrumb separator="/" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="javascript:void(0);">残疾人作品展销</a></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="search">
          <el-input placeholder="请输入搜索的商品"  clearable class="search_input" @clear="goSearch" v-model="parm.productName">
          </el-input>
          <el-button type="primary" class="btn" @click="goSearch">开始搜索</el-button>
        </div>
      </div>

      <div class="header">
        <!-- <div class="search">
          <el-input placeholder="请输入搜索的商品"  clearable class="search_input" @clear="goSearch" v-model="parm.productName">
          </el-input>
          <el-button type="primary" class="btn" @click="goSearch">开始搜索</el-button>
        </div> -->
        <div class="header_banner">
          <el-carousel height="300px">
            <el-carousel-item v-for="(item, index) in adLists" :key="index">
              <img :src="item.picSrc" alt :title="item.adName" @click="goRouter1(item.picUrl)" class="err_image cursorP" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="tab flex">
          <div class="color66 span_name">作品分类：</div>
          <el-radio-group v-model="parm.qydjCpfl1" class="world">
            <ul class="tab_a flex">
              <li @click="tabs('', '0')">
                <el-radio class="style_radio" label>新品发布</el-radio>
              </li>
              <li v-for="(n, index) in tab" :key="index" :class="{ active: active == index + 1 }" @click="productChilds(n.children,n)">
                <el-radio :label="n.value" class="style_radio">{{
                    n.label
                  }}
                </el-radio>
              </li>
            </ul>
            <el-collapse-transition>
              <el-radio-group v-model="parm.qydjCpfl2" v-show="show" class="collapse">
                <ul class="bottom flex flex-wrap childs" ref="getHeight">
                  <li>
                    <el-radio label>不限</el-radio>
                  </li>
                  <li v-for="(m, s) in productChild" :key="s" @click="productChilds1(m)">
                    <el-radio :label="m.value">{{ m.label }}</el-radio>
                  </li>
                </ul>
              </el-radio-group>
            </el-collapse-transition>
          </el-radio-group>
        </div>
      </div>
      <!-- 瀑布流 -->
      <div class="content">
        <waterfall :col="col" :data="data" :width="itemWidth" :gutterWidth="gutterWidth" id="vueWaterfall">
          <template>
            <div class="box" v-for="item in data" :key="item.index" @click="detail(item.id)">
               <div class="img" ><img v-lazy="item.productImage" :alt="item.productName" class="err_image" /></div>

              <h3 class="line_clamp2">{{ item.productName }}</h3>
              <p class="money">
                <span>￥</span>
                <span v-if="item.productPrice">{{ item.productPrice }}</span>
                <span v-else>面议</span>
              </p>
            </div>
          </template>
        </waterfall>
        <status type="post" des="暂无售卖中商品~" v-if="this.data == null || this.data.length <= 0"></status>
        <div v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"></div>
        <div class="pages">
            <el-pagination background class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="parm.pageNum" :page-size="parm.pageSize" layout="prev, pager, next, total, jumper" v-show="parm.total > 0" :total="parm.total">
            </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      search: '',
      active: 0,
      show: false,
      loading: false,
      tab: [],
      childTab: [],
      itemWidth: 282,
      gutterWidth: 24,
      data: [],
      col: 4,
      adLists: [],
      productChild: [],
      parm: {
        productName: '',
        qydjCpfl1: '',
        qydjCpfl2: '',
        pageNum: 1,
        pageSize: 16,
        total:0
      },
      hasNextPage: true,
    }
  },
  watch: {
    parm: {
      handler(newName, oldName) {
        // this.data = []
        // this.getProductList()
      },
      deep: true,
    },
  },

  methods: {
    // 去详情
    detail(ids) {
      const { href } = this.$router.resolve({
        path: '/shoppingMall/detail',
        query: {
          id: ids,
        },
      })
      window.open(href, '_blank')
    },
    goSearch() {
      this.parm.qydjCpfl1 = '';
      this.parm.qydjCpfl2 = '';
      this.parm.pageNum = 1;
      this.getProductList();
    },
    getProductList() {
      let that = this
      this.loading = true
      this.$api
        .productList(this.parm)
        .then((res) => {
          res = res.data
          if (res.success) {
            this.parm.total = res.data.total;
            this.loading = false
            // TODO 数组拼接
            const records = res.data?.records ?? []
            this.data = records
          } else {
            this.loading = false
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e)
        })
    },
    getProductCategoryList() {
      let arr = []
      this.$axios
        .get('/api/app-jycy-sysdict/commonDictValue', {
          dictCode: 'product_fir',
          maxLevel: 2,
        })
        .then((result) => {
          arr = result.data.data
          this.tab = arr
        })
    },
    getad() {
      this.$api.adApi({ classId: '026a58b65fc449f38adcd53f27d5808c' }).then((ref) => {
        this.adLists = ref.data.data
      })
    },
    productChilds(items, value) {
      this.productChild = items
      this.show = true
      this.parm.qydjCpfl1 = value.value;
      this.getProductList();
    },
    productChilds1(m){
       this.parm.qydjCpfl2 = m.value;
      this.getProductList();
    },
    //进入搜索页面
    goRouter() {
      const { href } = this.$router.resolve({
        path: '/shopping/search',
        query: {
          productName: this.parm.productName,
        },
      })

      window.open(href, '_blank')
    },
    goRouter1(url) {
      // const { href } = this.$router.resolve({
      //   path: '/shopping/search',
      //   query: {
      //     productName: this.parm.productName,
      //   },
      // })
      if(url){
        location.href= url
      }
      window.open(href, '_blank')
    },
    tabs(code, index) {
      let that = this
      that.active = index
      that.parm.qydjCpfl1 = code
      that.parm.qydjCpfl2 = ''
      if (index === '0') {
        this.show = false
      }
      // console.log(code);
      this.getProductList()
    // this.getProductCategoryList()
    },
    scrolls(scrollData) {
      // var w = document.getElementById("vueWaterfall");
      var scroll = document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scroll + window.innerHeight, document.body.clientHeight);
      if (scroll + window.innerHeight >= document.body.clientHeight) {
        this.parm.pageSize = 16
        setTimeout(() => {
          if (this.hasNextPage) {
            this.getProductList()
          }
        }, 500)
      }
    },
    //分页
    handleCurrentChange: function (val) {

      this.parm.pageNum = val;
      this.getProductList();
      // this.news()
      // window.scrollTo(0, 0)
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
    },
  },

  created() {
    window.addEventListener('scroll', this.scrolls)
    this.getad()
    this.getProductList()
    this.getProductCategoryList()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrolls)
  },
}
</script>
<style scoped lang="less">
@import './css/shopping.less';
.nav{
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  color: #6d6d6d;
  /deep/  .el-breadcrumb{
      margin-top: 2px;
    }
    a{
      color: #6d6d6d;
    }
}
/deep/ .img{
    width: 234px;
    height: 234px;
    overflow: hidden;
    margin: 24px auto 0;
}
/deep/ .world.el-radio-group .el-radio .el-radio__label {
  // font-size: 18px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #66716C;
  line-height: 28px;
}
/deep/ .world.el-radio-group .el-radio{
  padding:3px 6px;
  border-radius: 0;
}
/deep/ .world.el-radio-group .is-checked .el-radio__label {
  // color: #00924b;
  // background-color: #ffffff;
  // font-weight: 600;
  // width: 70px;
  // height: 28px;
  // background: #28A46D;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 28px;
}
/deep/ .world.el-radio-group .is-checked {
  background: #28A46D;
  color: #fff;
  position: relative;
}

/deep/ .el-radio-group {
  .flex-wrap {
    background-color: rgba(25, 137, 203, 0.1);
    border-radius: 4px;
  }
}
/deep/.pagination{
  .btn-prev,.el-pager li, .btn-next, .el-input__inner{
    background: rgba(0,18,10,0.05);
  }
  .el-pagination__editor.el-input{
    width: 48px;
    height: 25px;
  }
  }
</style>
